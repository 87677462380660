import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import Page from './page';
import {
  preLoginToken,
  addRequestingClinicNumber,
  setNotificationAuthLogoutRoute,
} from '../state/user';
import { clearPros, createProData } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import HiddenContent from '../components/hidden-content';
import { apiFetch } from '../lib/fetch';
import config from '../config';
import { throttledReset } from '../initializers/activity';
import { createVitalsRunnerWebWorker } from '../lib/vitals-runner';
import Logo from '../components/logo';
import GeneralLayout from '../components/general-layout';
import { setEmbedded, postContainerMessage, events } from '../lib/comms';

const acceptableProTypes = [
  'VITAL-TRAC',
  'BUNDLED_EVENT',
];

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '350px',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.25rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
    textAlign: 'center',
    width: '100%',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    alignSelf: 'center',
    color: colors.errorRed,
    margin: 15,
    fontWeight: 800,
    fontSize: fontSizing.small,
    textAlign: 'center',
  },
};
const defaultMessage = 'This link may no longer be used. Please close the browser window.';
class ShadowConfirmation extends Page {
  constructor(props) {
    super(props);
    this.state = {
      dob: '',
      error: '',
      clinic_id: null,
      clinicNumber: null,
      clinic_logo: '',
      notificationType: '',
      notificationExpired: false,
      additionalDobAuthErrorMessage: '',
      showDobAuthErrorMessage: false,
    };

    this.props.setNotificationAuthLogoutRoute(window.location.pathname);

    this.activityTimer = setInterval(throttledReset, 10000);
  }

  async componentWillMount() {
    setEmbedded(true);
    const clinic = await apiFetch(`/notifications_clinic/${this.props.params.notificationId}`);
    if (!acceptableProTypes.includes(clinic.notification_type)) window.location.replace(`${config.PATIENT_APP_URL}/notifications/${this.props.params.notificationId}`);
    // const { token } = this.props.location.query;
    this.setState({
      clinic_id: clinic.id,
      clinic_name: clinic.name,
      clinicNumber: clinic.phone,
      notificationType: clinic.notification_type,
      notificationExpired: clinic.notificationExpired || false,
    });
    document.title = clinic.name;
    const favicon = document.getElementById('favicon');
    favicon.href = 'img/favicon.ico';
    const logo = `img/vital-trac.png`;
    this.setState({
      clinic_logo: logo,
    });
    this.tokenListener = (token) => {
      this.props.preLoginToken(token, this.props.params.notificationId)
      .then((data) => {
        postContainerMessage('authed', true);
        this.props.createProData(data.notification_type, {}, data.meta.pro_submission_id || data.meta.video_broadcast_id);
        this.props.addRequestingClinicNumber(this.state.clinicNumber);
        this.props.router.push({ pathname: '/notification-intro', state: { id: data.meta.pro_submission_id || data.meta.video_broadcast_id, type: data.notification_type, clinic_id: this.state.clinic_id, dob: this.state.dob } });
      })
      .catch((e) => {
        const { lang } = this.props.location.query;
        let message = get(e, 'content.message') || get(e, 'content.error') || defaultMessage;
        if (e.status === 401 && 'failedLoginAttemptMessage' in e.content) {
          const additionalDobAuthErrorMessage = e.content.failedLoginAttemptMessage;
          return this.setState({ dob: '', additionalDobAuthErrorMessage, showDobAuthErrorMessage: true });
        }
        if (e.status === 403) {
          return this.props.router.push('/login?originalPath=/notifications&msg=notification');
        }
        if (e.status === 404) {
          message = 'THE DATE OF BIRTH ENTERED DOES NOT MATCH YOUR PATIENT RECORD.';
          if (lang === 'es') {
            message = 'LA FECHA DE NACIMIENTO INTRODUCIDA NO COINCIDE CON SU REGISTRO DE PACIENTE.';
          }
        } else if (!navigator.onLine) {
          message = 'Lost Internet connection.  Please try again when you’re connected to the Internet.';
        } else if (e.status !== 410) {
          message = 'Something went wrong. Please try again later.';
        }
        this.setState({
          dob: '',
          error: message,
        });
      });
    }
    events.on('token', this.tokenListener);
    postContainerMessage('readyForToken', true);
    createVitalsRunnerWebWorker();
  }

  componentWillUnmount() {
    clearInterval(this.activityTimer);
    events.off('token', this.tokenListener);
  }

  handleChange = (dob) => {
    this.setState({ dob });
  }

  render() {
    const { notificationExpired } = this.state;

    return (
      <GeneralLayout
        hidden
        buttonText="next"
        buttonOnClick={this.onSubmission}
      >
        <Logo />
        {notificationExpired ? (
          <div>
            <p style={{ ...styles.error, fontWeight: 'normal' }}>The notification link has expired.</p>
            <p style={styles.error}><strong>Please check your messages for a more recent link or contact your healthcare provider.</strong></p>
            <p style={{ ...styles.error, fontWeight: 'normal' }}>You may now close this window</p>
          </div>
        ) : (
          <div style={styles.container}>
            <HiddenContent hidden={!this.state.error}>
              <div style={styles.error}>
                {this.state.error}
              </div>
            </HiddenContent>
            <CircularProgress/>
          </div>
        )}
      </GeneralLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms } = state;
  return { proForms };
}

export default connect(mapStateToProps, {
  addRequestingClinicNumber,
  clearPros,
  createProData,
  preLoginToken,
  setNotificationAuthLogoutRoute,
})(ShadowConfirmation);
