import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NoSleep from 'nosleep.js';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { forward } from './navigation';

import { resetVitals, updateVitals } from '../state/vitals';
import HiddenContent from '../components/hidden-content';
import { startVideo } from '../lib/createAnswer';
import { colors, fontSizing } from '../lib/styles';
import { HeartBeat, Lungs } from '../lib/icons';
import startVitalsRunner, {
  addCallbacksToWebWorker,
  isWebWorkerReady,
  loadDetector,
  preparationVideo,
  resetVitalsRunnerOnUnmount,
  stopPreparationVideo,
  startSessionJS,
} from '../lib/vitals-runner';
import { vcDataStatusMessageMap, vcProcessDataStatus } from '../lib/vital-core';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { postContainerMessage, isEmbedded } from '../lib/comms';

const dataStatusWarningGracePeriod = 1000; // used to at the very beginning of data capture for the specified duration

const lightGreen = '#acdad2';
const green = '#2ea490';
const lightRed = '#e19bab';
const red = '#b30631';
const lightGrey = '#eeeeee';

const styles = {
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  exclamationWrapper: {
    marginTop: '10rem',
    marginBottom: '8rem',
    '& path': {
      fill: colors.errorRed,
    },
    textAlign: 'center',
  },
  exclamationWrapperSmall: {
    display: 'inline-block',
    marginRight: 10,
    width: 25,
    '& path': {
      fill: 'white',
    },
  },
  errorHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgressForPro: {
    background: colors.lightBrightBlue,
  },
  orderedList: {
    marginTop: 0,
    paddingLeft: 25,
  },
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  loadingModel: {
    fontSize: fontSizing.body,
    position: 'relative',
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 580,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  topSection: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  patientCanvasVisible: {
    minHeight: '450px',
    height: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'block',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    width: '100%',
  },
  imReadyButton: {
    background: green,
    borderRadius: 30,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: 18,
    maxWidth: 580,
    height: 40,
    marginLeft: 10,
    marginRight: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  imReadyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogText: {
    fontSize: fontSizing.body,
  },
  vitalCoreErrorContainer: {
    background: colors.errorRed,
    color: 'white',
    fontSize: fontSizing.body,
    padding: 10,
  },
  vitalCoreErrorText: {
    textAlign: 'center',
  },
  vitalCoreErrorRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  linearProgressText: {
    color: 'white',
    left: 10,
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    zIndex: 2,
  },
  processingSection: {
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 60,
    textAlign: 'center',
  },
  circularProgressContainer: {
    alignItems: 'center',
    backgroundColor: colors.questionBackground,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    position: 'relative',
  },
  circularProgressText: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  circularProgressTextPercent: {
    fontSize: fontSizing.smallXX,
  },
  errorSection: {
    fontSize: fontSizing.body,
    maxWidth: 600,
    padding: 10,
    '& a': {
      fontSize: fontSizing.body,
    },
  },
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  retryButton: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: 40,
    width: '100%',
    minHeight: '4rem',
    fontSize: fontSizing.body,
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: colors.white,
      },
    },
  },
  menuButtonOuterWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  menuButtonWrapper: {
    background: 'rgba(211,211,211,0.25)',
    borderBottomLeftRadius: 5,
    display: 'flex',
    justifyContent: 'end',
    padding: 10,
  },
  vitalsMeasurements: {
    width: '100%',
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  vitalsMeasurementsRow: {
    display: 'flex',
    maxWidth: 600,
    flexGrow: 1,
  },
  vitalsMeasurementsRowWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
  },
  vitalsMeasurement: {
    position: 'relative',
    zIndex: 2,
    color: 'black',
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
  },
  vitalsMeasurementWrapper: {
    position: 'relative',
    borderRadius: 30,
    flexBasis: 0,
    flexGrow: 1,
    margin: 10,
    background: 'white',
    overflow: 'hidden',
  },
  vitalsMeasurementIconWrapper: {
    position: 'absolute',
    top: 10,
    left: -10,
    zIndex: 1,
    height: 70,
    width: 70,
  },
  circularProgressWrapper: {
    height: 30,
    width: 30,
    marginTop: 10,
    marginBottom: 10,
  },
  linearProgressWrapper: {
    borderRadius: 30,
    position: 'relative',
  },
  bottomContentContainer: {
    height: 40,
    margin: 15,
    width: '100%',
  },
  opaqueBackground: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    background: 'rgb(255, 255, 255, 0.5)',
    borderRadius: 30,
  },
  textBody: {
    fontSize: 18,
  },
  textSmall: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  guideBoxWrapper: {
    flexGrow: 1,
    padding: 20,
  },
  doubleDash: {
    textAlign: 'center',
  },
};

const CustomLinearProgress = withStyles(() => ({
  root: {
    height: 40,
    borderRadius: 30,
    zIndex: 2,
    position: 'relative',
    background: 'none',
  },
  colorPrimary: {
    backgroundColor: 'none',
  },
  bar: {
    borderRadius: 30,
    backgroundColor: props => (props.good ? green : red),
  },
}))(({ good, ...props }) => <LinearProgress variant="determinate" {...props} />);

const GuideBox = ({ color }) => (
  <svg viewBox="0 0 260 350" className="svg" style={{ height: '100%', width: '100%' }}>
    <path d="M3,130 a124,124 0 0,1 254,0" fill="none" strokeWidth="6" stroke={color}/>
    <path d="M3,220 a124,124 0 0,0 254,0" fill="none" strokeWidth="6" stroke={color}/>
    <line x1="3" y1="130" x2="3" y2="220" strokeWidth="6" stroke={color} />
    <line x1="257" y1="130" x2="257" y2="220" strokeWidth="6" stroke={color} />
  </svg>
);

class MeasureVitals extends React.Component {
  constructor(props) {
    super(props);
    console.log('props', props);
    this.state = {
      // cameraState: '',
      // cameraError: '',
      me: {}, // todo unset in code 
      // stream: undefined,
      // signalPercent: 0,
      // workerPct: 0,
      workerReady: false,
      // md: null,
      // errorDialogOpen: false,
      timeLeft: 0,
      percentLeft: 1,
      // processing: false,
      // cachedFrameCount: 0,
      // processedFrameCount: 0,
      // latestTouchTap: {
      //   time: 0,
      //   target: null,
      // },
      startedVitalsMeasurements: false,
      patientVideoDataLoaded: false,
      showImReady: true,
      coreWarnings: [],
      // signsMsgs: [],
      patientCanvasVisibleHeight: null,
      patientCanvasVisibleWidth: null,
      HR: null,
      BR: null,
      hrSignalPercent: null,
      brSignalPercent: null,
      // bpSignalPercent: null,
      // spo2SignalPercent: null,
      sessionComplete: false,
      guideBoxColor: colors.green,
      canStartMeasurements: false,
    };

    this.patientCanvas = React.createRef();
    this.patientCanvasVisible = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.firstSignTimestamp = React.createRef();

    this.patientCanvasVisibleRAF = null;

    this.moduleDownloadingTimer = null;
    this.processingTimer = null;
    this.noSleep = null;

    addCallbacksToWebWorker({
      vrOnsigns: this.vrOnsigns,
      vrOnprocessing: this.vrOnprocessing,
      vrOnend: this.vrOnend,
    });

    this.reloadingVitalsRunner = false;
    this.startWorkerRAF = null;

    this.props.resetVitals();
  }

  componentDidMount = async () => {
    await this.startPreparationVideo();
    this.initiateVitalsRunner();
  }

  componentWillUnmount = () => {
    this.endCall();
    stopPreparationVideo();
    window.cancelAnimationFrame(this.patientCanvasVisibleRAF);
    window.cancelAnimationFrame(this.startWorkerRAF);
    if (this.noSleep) this.noSleep.disable();
    const videoElement = this.patientVideo.current;
    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }
    if (!this.reloadingVitalsRunner && this.state.workerReady) {
      // only reset vitals runner state when core is not getting reloaded,
      // and when web worker has been initialized
      resetVitalsRunnerOnUnmount();
    }
  }

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      clearTimeout(this.processingTimer);
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  handleRetry = async () => {
    this.setState({
      // cameraError: '',
      // errorDialogOpen: false,
      showImReady: true,
    });

    await this.startPreparationVideo();
  };

  startPreparationVideo = async () => {
    const { me } = this.state;

    return new Promise(async (resolve) => {
      try {
        const stream = await startVideo(me, {
          audio: false,
          video: {
            facingMode: 'user',
          },
        });
        const patientVideo = this.patientVideo.current;
  
        patientVideo.addEventListener('loadeddata', () => {
          loadDetector();
          const patientCanvasVisible = this.patientCanvasVisible.current;
          preparationVideo(patientVideo, patientCanvasVisible);
          this.setState({ patientVideoDataLoaded: true });
          resolve();
        });
  
        patientVideo.srcObject = me.stream;
        try {
          await patientVideo.play();
        } catch (err) {
          console.error('error starting video', err);
        }
  
        this.setState({
          patientCanvasVisibleHeight: patientVideo.videoHeight,
          patientCanvasVisibleWidth: patientVideo.videoWidth,
          // stream,
        });
      } catch (err) {
        // this.setState({ cameraError: err, errorDialogOpen: true });
      }
    });
  };

  addWarning = (warningCode) => {
    const { coreWarnings } = this.state;

    const warningCodeMessage = vcDataStatusMessageMap[warningCode];

    if (coreWarnings.findIndex(warning => warning.code === warningCode) === -1) {
      this.setState((prevState) => {
        const coreWarningObj = {
          code: warningCode,
          message: warningCodeMessage,
        };
        return { coreWarnings: [...prevState.coreWarnings, coreWarningObj] };
      });
      // setGuideBoxColor('red');
      this.setState({ guideBoxColor: colors.red });

      setTimeout(() => {
        this.setState((prevState) => {
          const filteredWarnings = prevState.coreWarnings.filter(curWarning => curWarning.code !== warningCode);

          if (!filteredWarnings.length) {
            // setGuideBoxColor('green');
            this.setState({ guideBoxColor: colors.green });
          }

          return { coreWarnings: filteredWarnings };
        });
      }, 3000);
    }
  };

  vrOndownloadingPct = (data) => {
    this.setState({ workerPct: data.pct });
  };

  vrOntimeLeft = (timeLeft, percentLeft) => {
    this.setState({ timeLeft, percentLeft });
  };

  vrOnprocessing = (processing) => {
  };

  vrGetAuthToken = async (getRunToken) => {
    const { user } = this.props;
    const runToken = JSON.parse(await getRunToken());
    const options = makeShadowOptions(runToken, user.token, 'POST');
    const result = await apiFetch(`/users/${user.id}/lite/vital_core/auth`, options);
    return result.token;    
  };

  vrLicenseError = () => {
    if (isEmbedded()) {
      postContainerMessage('licenseError', true);
    }
    browserHistory.push('/license-error');
  };

  vrOnsigns = (data) => {
    const { signs } = data;
    const {
      hrSignalPercent,
      brSignalPercent,
      bpSignalPercent,
      spo2SignalPercent,
      HR,
      BR,
    } = signs;
    let { dataStatus } = signs;

    if (!this.firstSignTimestamp.current) {
      this.firstSignTimestamp.current = Date.now();

      // while warnings are suppressed, disable start button
      setTimeout(() => {
        this.setState({ canStartMeasurements: true });
      }, 1000);
    }

    // warnings are suppressed at the very beginning of data capture for the duration specified by dataStatusWarningGracePeriod
    if ((Date.now() - dataStatusWarningGracePeriod) < this.firstSignTimestamp.current) {
      dataStatus = '';
    }

    const processedDataStatus = vcProcessDataStatus(dataStatus);

    if (processedDataStatus.action === 'warning') {
      processedDataStatus.data.forEach((dataStatusMessage) => { this.addWarning(dataStatusMessage); });
    }

    this.setState({
      hrSignalPercent,
      brSignalPercent,
      bpSignalPercent,
      spo2SignalPercent,
      HR,
      BR,
    });
  };

  vrOnend = (lastSigns) => {
    const { HR, BR } = lastSigns;
    const { router, updateVitals, location, vitals } = this.props;

    let {
      brOutOfRangeCount,
      hrOutOfRangeCount,
    } = vitals;

    if ((BR < 10 || BR > 22) && brOutOfRangeCount < 2) brOutOfRangeCount++;
    if ((HR < 40 || HR > 120) && hrOutOfRangeCount < 2) hrOutOfRangeCount++;

    this.setState({
      HR,
      BR,
      sessionComplete: true,
    });
    updateVitals({
      HR,
      BR,
      brOutOfRangeCount,
      hrOutOfRangeCount
    });
    forward(router, location.query);
  };

  initiateVitalsRunner = () => {
    try {
      const patientVideo = this.patientVideo.current;
      const canvas = this.patientCanvas.current;
      const patientCanvasVisible = this.patientCanvasVisible.current;

      this.noSleep = new NoSleep();
      this.noSleep.enable();

      const startWhenWorkerIsReady = () => {
        const workerIsReady = isWebWorkerReady();
        if (workerIsReady) {
          this.setState({ workerReady: true });
          clearTimeout(this.moduleDownloadingTimer);
          startVitalsRunner({
            video: patientVideo,
            canvas,
            patientCanvasVisible, 
            callbacks: {
              vrOnprocessing: this.vrOnprocessing,
              vrOntimeLeft: this.vrOntimeLeft,
              vrOnend: this.vrOnend,
              vrGetCoreWarnings: this.vrGetCoreWarnings,
              vrGetAuthToken: this.vrGetAuthToken,
              vrLicenseError: this.vrLicenseError,
            }
          });
          window.cancelAnimationFrame(this.startWorkerRAF);
        } else {
          this.startWorkerRAF = window.requestAnimationFrame(startWhenWorkerIsReady);
        }
      };

      this.startWorkerRAF = window.requestAnimationFrame(startWhenWorkerIsReady);
    } catch (err) {
      this.setState({ cameraError: err, errorDialogOpen: true });
    }
  }

  startSession = () => {
    this.setState({
      cameraState: 'started',
      showImReady: false,
      startedVitalsMeasurements: true,
    });

    startSessionJS(
      {
        vrOnprocessing: this.vrOnprocessing,
        vrOntimeLeft: this.vrOntimeLeft,
      },
    );
  }

  vrGetCoreWarnings = () => {
    return this.state.coreWarnings;
  }

  render() {
    const { classes } = this.props;
    const {
      startedVitalsMeasurements,
      patientVideoDataLoaded,
      showImReady,
      patientCanvasVisibleHeight,
      patientCanvasVisibleWidth,
      HR,
      BR,
      hrSignalPercent,
      brSignalPercent,
      sessionComplete,
      guideBoxColor,
      coreWarnings,
      canStartMeasurements,
    } = this.state;
    const videoContainerStyle = { display: this.state.cameraDenied ? 'none' : '' };

    // some dataStatus codes have the same message. prevent displaying redundant messages
    const coreWarningsSet = new Set();
    coreWarnings.forEach((coreWarning) => {
      coreWarningsSet.add(coreWarning.message);
    });
    const coreWarningsFiltered = Array.from(coreWarningsSet);

    // disable start button if a warning message is displayed on the screen
    const disableStartButton = !!coreWarningsFiltered.length;

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div
            id="videoContainer"
            className={classes.videoContainer}
            style={videoContainerStyle}
            ref={this.videoContainer}
          >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
            <canvas
              id="patientCanvasVisible"
              ref={this.patientCanvasVisible}
              className={classes.patientCanvasVisible}
              height={patientCanvasVisibleHeight}
              width={patientCanvasVisibleWidth}
            />
          </div>
          <div className={classes.nonVideoContentContainer}>
            <div className={classes.guideBoxWrapper}>
              <GuideBox color={guideBoxColor} />
            </div>
            <div className={classes.vitalsMeasurements}>
              <div className={classes.vitalsMeasurementsRowWrapper}>
                <div className={classes.vitalsMeasurementsRow}>
                  <div className={classes.vitalsMeasurementWrapper}>
                    <div className={classes.vitalsMeasurementIconWrapper}>
                      <HeartBeat style={{ color: lightGrey }} />
                    </div>
                    <div className={classes.vitalsMeasurement}>
                      <div className={classes.textSmall}>Heart rate</div>
                      {!sessionComplete ? (
                        <div className={classes.circularProgressWrapper}>
                          <CircularProgressbar
                            value={hrSignalPercent * 100}
                            strokeWidth={12}
                            styles={buildStyles({
                              pathTransitionDuration: 0.15,
                              trailColor: lightRed,
                              pathColor: red,
                            })}
                          />
                          <div className={classes.doubleDash}>{HR || '--'}</div>
                          {/* <CircularProgressWithLabel value={hrSignalPercent * 100} /> */}
                        </div>
                      ) : (
                        <div className={classes.doubleDash}>{HR || '--'}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.vitalsMeasurementsRowWrapper}>
                <div className={classes.vitalsMeasurementsRow}>
                  <div className={classes.vitalsMeasurementWrapper}>
                    <div className={classes.vitalsMeasurementIconWrapper}>
                      <Lungs style={{ color: lightGrey }} />
                    </div>
                    <div className={classes.vitalsMeasurement}>
                      <div className={classes.textSmall}>Breathing rate</div>
                      {!sessionComplete ? (
                        <div className={classes.circularProgressWrapper}>
                          <CircularProgressbar
                            value={brSignalPercent * 100}
                            strokeWidth={12}
                            styles={buildStyles({
                              pathTransitionDuration: 0.15,
                              trailColor: lightGreen,
                              pathColor: green,
                            })}
                          />
                          <div className={classes.doubleDash}>{BR || '--'}</div>
                        </div>
                      ) : (
                        <div className={classes.doubleDash}>{BR || '--'}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.bottomContentContainer}>
              <HiddenContent hidden={!showImReady}>
                <div className={classes.imReadyButtonWrapper}>
                  <button
                    className={classes.imReadyButton}
                    onClick={this.startSession}
                    type="button"
                    disabled={!patientVideoDataLoaded || disableStartButton || !canStartMeasurements}
                  >
                    BEGIN
                  </button>
                </div>
              </HiddenContent>
              <HiddenContent hidden={!!showImReady}>
                <div className={classes.loadingModel}>
                  <div className={classes.linearProgressWrapper}>
                    <div className={classes.opaqueBackground} />
                    <CustomLinearProgress good={guideBoxColor === colors.green} value={Math.min(100, Math.round((1 - this.state.percentLeft) * 100))} />
                  </div>
                </div>
              </HiddenContent>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

MeasureVitals.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { vitals, user } = state;

  return { vitals, user }
}

export default connect(mapStateToProps, { resetVitals, updateVitals })(withStyles(styles)(MeasureVitals));
