const { EventEmitter } = require('events');

let embedded = false;
export const events = new EventEmitter();

export function setEmbedded(value) {
  embedded = value;
}

export function isEmbedded() {
  return embedded;
}

export function postContainerMessage(type, data) {
  if (embedded) {
    window.parent.postMessage({ type, data }, '*');
  }
}

window.addEventListener('message', (event) => {
  if (event.data.type === 'token' && event.data.data) {
    events.emit('token', event.data.data);
  }
});
