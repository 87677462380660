import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import Page from './page';
import { colors, fontSizing, desktopMaxWidth } from '../lib/styles';
import { HeartBeat, Lungs, CircleExclamationSolid, NoInternet } from '../lib/icons';
import Continue from '../components/continue';
import { resetVitals } from '../state/vitals';
import env from '../config';
import { submitPRO } from '../state/pro-forms';
import { isEmbedded, postContainerMessage } from '../lib/comms';

const MAX_RETAKES = 3;
const MAX_CRITICAL_ERRORS = 2;

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  boldText: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: colors.green,
    height: 50,
    borderRadius: 25,
    borderStyle: 'none',
    width: '100%',
    color: 'white',
    fontSize: fontSizing.body,
    maxWidth: desktopMaxWidth,
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  page: {
    background: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    height: '100%',
  },
  pageContent: {
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 540,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%', // needed to make buttons on the bottom of the page span up to the maxWidth
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalsRed: {
    color: '#cd3636',
  },
  centerText: {
    textAlign: 'center',
  },
  vitalsMeasurement: {
    position: 'relative',
    zIndex: 2,
    color: 'black',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
  },
  vitalsMeasurementWrapper: {
    position: 'relative',
    borderRadius: 20,
    flexBasis: 0,
    flexGrow: 1,
    margin: 10,
    background: 'white',
    overflow: 'hidden',
  },
  vitalsMeasurementIconWrapper: {
    position: 'absolute',
    left: -20,
    zIndex: 1,
    height: 80,
    width: 80,
  },
  lungIconWrapper: {
    position: 'absolute',
    top: 10,
    left: -20,
    zIndex: 1,
    height: 80,
    width: 80,
  },
  vitalsMeasurementTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    zIndex: 2,
  },
  textBold: {
    fontWeight: 'bold',
  },
  textLarge: {
    fontSize: 32,
  },
  textBody: {
    fontSize: 18,
  },
  textSmall: {
    fontSize: 10,
  },
  textRed: {
    color: colors.red,
  },
  textGreen: {
    color: colors.green,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 40,
    textAlign: 'center',
  },
  disclaimer: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 20,
    textAlign: 'center',
  },
  vitalsValuesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  vitalMeasurementUnit: {
    position: 'absolute',
    right: 0,
    top: '90%',
    fontSize: 10,
  },
  subText: {
    marginBottom: 30,
    textAlign: 'center',
  },
  image: {
    maxWidth: 500,
    width: '100%',
    marginTop: 50,
  },
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconRedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
    '& path': {
      fill: colors.errorRed,
    },
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  btn: {
    width: '48%',
  },
};

const paperStyles = {
  position: 'relative',
  boxShadow: '0 0 8px lightgrey',
  display: 'flex',
  marginBottom: '10px',
  marginTop: '10px',
  padding: '25px',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '20px',
};

const PaperStyled = ({ children }) => (
  <Paper style={paperStyles}>{children}</Paper>
);

const LightingAndMovementErrorMessage = ({ classes }) => (
  <div>
    <div className={classes.textCentered}>
      <i>We are unable to measure your vitals because of a combination of movement and lighting errors.</i>
    </div>
    <p className={`${classes.boldText} ${classes.marginBottom0}`}>Please ensure the following:</p>
    <ul className={classes.list}>
      <li>- Hold the phone still</li>
      <li>- Face is well lit</li>
      <li>- Face is within the guide box</li>
    </ul>
  </div>
);
LightingAndMovementErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};
const LightingAndMovementErrorMessageStyled = withStyles(styles)(LightingAndMovementErrorMessage);

const dataStatusMessageMap = {
  'W-HRT-062': <LightingAndMovementErrorMessageStyled />,
};

const dataStatusInstructionsMap = {
  'W-HRT-062': <>and then tap <strong>RETRY.</strong></>,
};

const dataStatusHeaderMap = {
  'W-HRT-062': 'Lighting and Movement Error',
};

const dataStatusPageTitleMap = {
  'W-HRT-062': 'Error',
};

let pageTitle = 'Vital Results';
let actionType = 'continue';
let headerText = '';
let messageText = '';
let instructionsText = 'Please tap Retry';
let vitalsOutOfRange = false;
let displayResults = false;

const processResults = (results) => {
  const {
    HR,
    BR,
    // BP_SYS,
    // BP_DIA,
    // SPO2,
    criticalErrorCount,
    shouldRestart,
    shouldReload,
    errorCode,
    deviceError,
    retakes,
    brOutOfRangeCount,
    hrOutOfRangeCount,
  } = results;

  const maxSessionCount = retakes && retakes === MAX_RETAKES;
  const maxCriticalErrorCount = criticalErrorCount && criticalErrorCount === MAX_CRITICAL_ERRORS;

  const showBrOutOfRange = BR > 0 && (BR < 10 || BR > 22) && brOutOfRangeCount < 2;
  const showHrOutOfRange = HR > 0 && (HR < 40 || HR > 120) && hrOutOfRangeCount < 2;
  const brUnableToMeasure = BR <= 0;
  const hrUnableToMeasure = HR <= 0;

  const noResults = HR <= 0
  || BR <= 0
  || HR === '--'
  || BR === '--'
  // || BP_SYS <= 0
  // || BP_DIA <= 0
  // || SPO2 <= 0;
  const resultsOutOfRange = HR < 40
  || HR > 150
  || BR < 4
  || BR > 20
  // || BP_SYS < 90
  // || BP_SYS > 140
  // || BP_DIA < 60
  // || BP_DIA > 90
  // || SPO2 < 95;

  if (isEmbedded()) {
    postContainerMessage('results', results);
  }

  if (deviceError) {
    headerText = 'Device Error';
    messageText = 'Your vitals cannot be taken on this device. Vital-Trac is not compatible with your device hardware.';
    instructionsText = <>Please tap <strong>FINISH</strong> to let your provider know your vitals were not measured.</>;
    pageTitle = 'Device Error';
    actionType = 'finish';
  } else if ((shouldRestart || shouldReload || noResults || resultsOutOfRange) && (maxSessionCount || maxCriticalErrorCount)) {
    headerText = 'Error';
    messageText = 'Your vitals cannot be taken at this time. Please try again later.';
    instructionsText = <>Please tap <strong>FINISH</strong>.</>;
    pageTitle = 'IVC Error';
    actionType = 'finish';
  } else if (shouldRestart || shouldReload) {
    headerText = dataStatusHeaderMap[errorCode];
    messageText = dataStatusMessageMap[errorCode];
    pageTitle = dataStatusPageTitleMap[errorCode];
    instructionsText = dataStatusInstructionsMap[errorCode];
    actionType = 'restart';
  } else if (showBrOutOfRange || showHrOutOfRange) {
    let vitalOutOfRange;
    if (showBrOutOfRange && showHrOutOfRange) {
      vitalOutOfRange = 'measures appear';
    } else if (showBrOutOfRange) {
      vitalOutOfRange = 'respiratory rate appears';
    } else {
      vitalOutOfRange = 'pulse rate appears';
    }
    vitalsOutOfRange = true;
    messageText = `Your ${vitalOutOfRange} outside of normal range. Let's double check your measures.`;
    instructionsText = <>Please tap the <strong>RETAKE VITALS</strong> button.</>;
    actionType = 'restart';
    displayResults = true;
  } else if (brUnableToMeasure || hrUnableToMeasure) {
    if (brUnableToMeasure && hrUnableToMeasure) {
      headerText = 'Unable to Measure Vitals';
      messageText = <i>Vital Trac is unable to measure your vitals at this time.</i>;
      instructionsText = <>Please tap <strong>RETRY.</strong></>;
      actionType = 'restart';
    } else {
      messageText = <i>Vital Trac was unable to measure one of your vitals at this time.</i>;
      instructionsText = <>Please tap <strong>RETRY.</strong></>;
      actionType = 'restart';
      displayResults = true;
    }
    if (maxSessionCount) {
      instructionsText = <>Please tap <strong>END.</strong></>;
      actionType = 'continue';
    }
  } else {
    displayResults = true;
  }

  return {
    displayResults,
    headerText,
    instructionsText,
    messageText,
    pageTitle,
    actionType,
    vitalsOutOfRange,
    showBrOutOfRange,
    showHrOutOfRange,
    brUnableToMeasure,
    hrUnableToMeasure,
  };
};

class Results extends Page {
  state = {
    showErrorScreen: false,
    submissionAttempts: 0,
    submitting: false,
  }

  handleSubmission = (validMeasurements = true) => {
    const { user, vitals, location } = this.props;
    if (this.state.submitting) return;

    const { id, type } = location.query;

    const data = {
      vitals,
      ivcVersion: env.vitalCoreConfig.ivcVersion,
      webAppVersion: env.vitalCoreConfig.ivcWebAppVersion,
      noValidMeasurements: false,
    };

    if (!validMeasurements) {
      data.noValidMeasurements = true;
    }

    this.setState((prevState) => {
      return {
        submissionAttempts: prevState.submissionAttempts + 1,
        submitting: true,
      };
    });

    submitPRO({ userId: user.id, id }, {
      pro_type: type,
      pro_data: {
        data,
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.finishTrack(this.props.router);
      })
      .catch((error) => {
        this.setState({ submitting: false });

        if (!navigator.onLine) {
          const { pathname, search } = this.props.location;
          const curTrackIndex = this.getTrackIndex();
          const newPathname = pathname.replace(curTrackIndex + 1, curTrackIndex); // create pathname to previous page in pro

          const handleRetry = () => {
            this.props.router.push(`${newPathname}${search}`);
          };

          this.props.setErrorScreenData({
            header: 'Lost Internet Connection',
            messageOne: <i>Unable to submit your measurements.</i>,
            messageTwo: <>Please reconnect to the Internet and tap <strong>RETRY.</strong></>,
            notificationAuthLogoutRoute: this.props.user.notificationAuthLogoutRoute,
            pageTitle: 'No Internet',
            icon: <NoInternet />,
            onRetry: handleRetry,
            noLogout: true,
          });

          this.props.router.push('/timeout');
        } else {
          this.setState((prevState) => {
            if (prevState.submissionAttempts === 3) {
              this.props.clearPros();
            }
            return { showErrorScreen: true };
          });
        }
      });
  }

  handleSubmissionWithError = () => {
    this.handleSubmission(false);
  };

  handleRetake = () => {
    this.props.resetVitals();
    browserHistory.goBack();
  }

  handleGoToLogin = () => {
    const { logoutWithoutRedirect, router, user } = this.props;
    const { notificationAuthLogoutRoute } = user;

    logoutWithoutRedirect();

    router.push(notificationAuthLogoutRoute);
  }

  render() {
    const {
      classes,
      vitals,
    } = this.props;

    let displayResults;
    let headerText;
    let instructionsText;
    let messageText;
    let showBrOutOfRange;
    let showHrOutOfRange;
    let brUnableToMeasure;
    let hrUnableToMeasure;

    const { HR, BR } = vitals;

    const results = this.getDataMap(vitals);

        // since we clear pro data after three failed submission attempts, its possible that
    // getDataMap doesn't return pro, and will instead return an empty object. In order
    // to render this page with the failed submissions continuous error screen and not
    // create an error attempting to get an HR value when there isn't one, we need to
    // first check if there are results in redux to process
    if (Object.keys(results).length) {
      ({
        displayResults,
        headerText,
        instructionsText,
        messageText,
        pageTitle,
        actionType,
        vitalsOutOfRange,
        showBrOutOfRange,
        showHrOutOfRange,
        brUnableToMeasure,
        hrUnableToMeasure,
      } = processResults(results));
    } else if (!Object.keys(results).length && !showErrorScreen) {
      // Needed to prevent white screen from appearing if a user tries to navigate
      // back to this page from info-updated-successfully. Accessing HR when there are no
      // measurements (like after pro state has been cleared) will throw an error, and
      // prevent backButtonHandler in info-updated-successfully from executing. We still
      // want to render this page, however, when we need to show the failed submissions
      // continuous error and there is no data in redux
      return null;
    }

    const heartRate = HR > 0 ? HR : '--';
    const breathingRate = BR > 0 ? BR : '--';

    const hrInvalid = showHrOutOfRange || hrUnableToMeasure ? classes.vitalsRed : '';
    const brInvalid = showBrOutOfRange || brUnableToMeasure ? classes.vitalsRed : '';

    return (
      <div className={classes.pageContent}>
        <img className={classes.image} src="/img/vital-trac.png" alt="Mindset Medical Logo" />
        {
          displayResults ? (
            <>
              <h1 className={classes.title}>Complete!</h1>
              <PaperStyled>
                <div className={classes.vitalsMeasurementIconWrapper}>
                  <HeartBeat style={{ color: colors.lightGrey }} />
                </div>
                <div className={classes.vitalsMeasurementTextContainer}>
                  <div className={`${classes.textBody} ${classes.textBold} ${hrInvalid}`}>Heart Rate</div>
                  <div className={`${classes.textLarge} ${classes.textBold} ${hrInvalid}`}>{heartRate}</div>
                  <div className={classes.vitalMeasurementUnit}>bpm</div>
                </div>
              </PaperStyled>
              <PaperStyled>
                <div className={classes.lungIconWrapper}>
                  <Lungs style={{ color: colors.lightGrey }} />
                </div>
                <div className={classes.vitalsMeasurementTextContainer}>
                  <div className={`${classes.textBody} ${classes.textBold} ${brInvalid}`}>Breathing Rate</div>
                  <div className={`${classes.textLarge} ${classes.textBold} ${brInvalid}`}>{breathingRate}</div>
                  <div className={classes.vitalMeasurementUnit}>bpm</div>
                </div>
              </PaperStyled>
            </>
          ) : (
            <>
              <div className={classes.errorIconRedWrapper}>
                <div className={classes.errorIconInnerWrapper}>
                  <CircleExclamationSolid />
                </div>
              </div>
              <p className={`${classes.text} ${classes.boldText}`}>{headerText}</p>
              <p className={classes.text}>{messageText}</p>
              <p className={classes.text}>{instructionsText}</p>
            </>
          )
        }

        <div className={classes.btnContainer}>
          <Continue
            text="Retry"
            onClick={this.handleRetake}
            btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '48%', minWidth: 'auto' }}
          />
          <Continue
            text="Finish"
            onClick={this.handleSubmission}
            btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '48%', minWidth: 'auto', backgroundColor: colors.green }}
          />
        </div>

        <h3 className={classes.disclaimer}>vital-trac is intended to inform you of your general wellbeing and is not meant to be a substitute of the clinical judgement of your health care provider. vital-trac is not meant to diagnose, treat, mitigate, or prevent any symptom, disorder, disease or abnormal physical condition. Please consult directly with your health care provider to discuss any medical conditions.</h3>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    vitals,
    user,
  } = state;

  return {
    vitals,
    user
  }
}

export default connect(mapStateToProps, { resetVitals })(withStyles(styles)(Results));
